<template>
  <div class="pricing">
    <vs-tabs v-if="isTabsLoaded" class="pricing__tabs" v-model="activeTabIndex" :key="$i18n.locale">
      <vs-tab label="Let's Connect">
        <PricingPlans
          :class="{
            _locked: lockedUI
          }"
        />
      </vs-tab>

      <vs-tab icon-pack="feather" icon="icon-star" :label="$t('routes.vega')">
        <Vega :autoGetStarted="autoVegaGetStarted" :hasQuerySourceVega="hasQuerySourceVega" />
      </vs-tab>

      <vs-tab :label="$t('routes.gptConnect')" class="gpt-tab">
        <GptConnectPricingPlans />
      </vs-tab>

      <vs-tab icon="border_color" :label="$t('routes.payment')">
        <PricingSubscription @setActiveTabIndex="activeTabIndex = $event" />
      </vs-tab>

      <vs-tab icon-pack="feather" icon="icon-file" :label="$t('routes.invoices')">
        <PricingInvoices />
      </vs-tab>
    </vs-tabs>
  </div>
</template>
<!-- Global site tag (gtag.js) - Google Ads: 472108440 -->
<script async src="https://www.googletagmanager.com/gtag/js?id=AW-472108440"></script>

<script>
window.dataLayer = window.dataLayer || []
function gtag() {
  dataLayer.push(arguments)
}
gtag('js', new Date())

gtag('config', 'AW-472108440')
gtag('event', 'conversion', {
  send_to: 'AW-472108440/YWzdCNGb1fMBEJibj-EB',
  transaction_id: ''
})
</script>
<!-- Facebook Pixel Code -->
<script>
!(function (f, b, e, v, n, t, s) {
  if (f.fbq) return
  n = f.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
  }
  if (!f._fbq) f._fbq = n
  n.push = n
  n.loaded = !0
  n.version = '2.0'
  n.queue = []
  t = b.createElement(e)
  t.async = !0
  t.src = v
  s = b.getElementsByTagName(e)[0]
  s.parentNode.insertBefore(t, s)
})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
fbq('init', '781026859376593')
fbq('track', 'PageView')
</script>
<!-- End Facebook Pixel Code -->
<script>
fbq('track', 'Purchase', { currency: 'EUR', value: 49.0 })
</script>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

import PricingPlans from './pages/plans/PricingPlans.vue'
import GptConnectPricingPlans from './gpt-connect/subscription/GptConnectPricingPlans.vue'
import PricingSubscription from './pages/plans/PricingSubscriptionNew.vue'
import PricingInvoices from './pages/plans/PricingInvoices'
import Vega from './vega/subscription/Vega.vue'

import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    PricingPlans,
    GptConnectPricingPlans,
    PricingSubscription,
    PricingInvoices,
    Vega
  },

  data: () => ({
    isTabsLoaded: false,
    activeTabIndex: 0,
    company: null,
    companyName: null,
    affiliate: null,
    subscribed: false,
    isTabsLoaded: false,
    autoVegaGetStarted: false,
    hasQuerySourceVega: false
  }),

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      lockedUI: 'lockedUI',
      companyData: 'company'
    })
  },
  async created() {
    await this.getCompanyInfo()

    this.$serverBus.$on('successful-payment', () => {
      /* Refresh all on successful payment */
      const refreshState = {
        invoices: true,
        subscription: true,
        plans: true
      }
      this.updateRefreshState(refreshState)
      this.activeTabIndex = 1
    })

    this.$serverBus.$on('subscription-cancelled', () => {
      this.activeTabIndex = 0
      /* Refresh all on successful payment */
      const refreshState = {
        invoices: true,
        subscription: true,
        plans: true
      }
      this.updateRefreshState(refreshState)
    })
  },

  mounted() {
    this.hasQuerySourceVega = false

    this.useRouteParams()
    this.$serverBus.$on('show-invoices', () => {
      this.$serverBus.$emit('hide-ui-locked-status')
      this.activeTabIndex = 4
    })

    this.$serverBus.$on('show-plans', () => {
      this.activeTabIndex = 0
    })

    this.$serverBus.$on('show-vega', () => {
      this.activeTabIndex = 1
    })

    this.$serverBus.$on('show-gpt-connect', () => {
      this.activeTabIndex = 2
    })

    this.$serverBus.$on('update-payment-method', () => {
      this.$serverBus.$emit('hide-ui-locked-status')
      this.activeTabIndex = 1
    })
    if (this.$route && this.$route.query && this.$route.query.source && this.$route.query.source === 'vega') {
      this.hasQuerySourceVega = true
      this.activeTabIndex = 1
    }
    if (this.$route && this.$route.query && this.$route.query.showInvoice) {
      this.activeTabIndex = 0
      const refreshState = {
        invoices: true,
        subscription: true,
        plans: true
      }
      this.updateRefreshState(refreshState)
      setTimeout(() => {
        this.activeTabIndex = 4
      }, 3000)
    }
  },

  beforeDestroy() {
    this.$serverBus.$off('show-invoices')
    this.$serverBus.$off('show-plans')
    this.$serverBus.$off('show-gpt-connect')
    this.$serverBus.$off('show-vega')
    this.$serverBus.$off('update-payment-method')
    this.$serverBus.$off('successful-payment')
    this.$serverBus.$off('subscription-cancelled')
  },

  watch: {
    isVegaActivated() {
      if (this.isVegaActivated && this.companyData.mollieMandateId && this.hasQuerySourceVega) {
        this.$serverBus.$emit('vega-funnel-paying-process', 'successful-paypal-payment')
      }
    }
  },

  methods: {
    ...mapMutations({
      updateRefreshState: 'UPDATE_REFRESH_STATE'
    }),

    useRouteParams() {
      if (this.$route.params.action) {
        switch (this.$route.params.action) {
          case 'activate-vega': {
            setTimeout(() => {
              this.activeTabIndex = 1

              this.$nextTick(() => {
                this.$serverBus.$emit('hide-ui-locked-status')
              })
            }, 200)

            break
          }

          case 'activate-vega-get-started': {
            setTimeout(() => {
              this.autoVegaGetStarted = true
              this.activeTabIndex = 1

              this.$nextTick(() => {
                this.$serverBus.$emit('hide-ui-locked-status')
              })
            }, 200)

            break
          }

          case 'activate-gpt-get-started': {
            setTimeout(() => {
              this.autoVegaGetStarted = true
              this.activeTabIndex = 2

              this.$nextTick(() => {
                this.$serverBus.$emit('hide-ui-locked-status')
              })
            }, 200)

            break
          }

          case 'show-invoices':
            setTimeout(() => {
              this.activeTabIndex = 2

              this.$nextTick(() => {
                this.$serverBus.$emit('hide-ui-locked-status')
              })
            }, 200)

            break
          case 'update-payment-method':
            this.activeTabIndex = 0
            setTimeout(() => {
              this.$nextTick(() => {
                this.activeTabIndex = 1
                this.$serverBus.$emit('hide-ui-locked-status')
              })
            }, 1000)

            break
        }
      }
    },

    async getCompanyInfo() {
      await this.$vs.loading()

      const firebaseCurrentUser = firebase.auth().currentUser

      await firebaseCurrentUser.getIdToken(true)

      const idTokenResult = await firebaseCurrentUser.getIdTokenResult()

      if (idTokenResult && idTokenResult.claims.company) {
        const companyRef = await this.$db.collection('company').doc(idTokenResult.claims.company).get()
        const company = companyRef.data()

        if (company) {
          const cp = company

          cp.country = company.country ? company.country : ''
          cp.countryCode = company.countryCode ? company.countryCode : ''
          cp.id = companyRef.id

          this.company = cp
          this.companyName = companyRef.data().name
        }
      }

      if (this.company && this.company.affiliatePartner) {
        const companyaffiliate = this.$functions.httpsCallable('getAffiliateInfo')
        const affiliateData = await companyaffiliate({ affiliateId: this.company.affiliatePartner })

        if (affiliateData && affiliateData.data && affiliateData.data.company) {
          this.affiliate = { company: affiliateData.data.company, level: affiliateData.data.level }
        }
      }

      if (this.company.paymentStatus && this.company.paymentStatus === 'subscription') {
        this.subscribed = true
      }

      this.isTabsLoaded = true

      await this.$vs.loading.close()

      if (this.company && this.company.isAppSumoAccount) {
        this.$router.push({ name: 'appsumo-billing' })
      }
    }
  }
}
</script>

<style lang="scss">
$main-color: rgb(18, 89, 141);

.pricing {
  &__tabs {
    .vs-tabs {
      &--content {
        padding: 20px 0 0;
      }

      &--ul {
        box-shadow: none;
      }

      &--li {
        span {
          font-size: 16px;
        }

        &:nth-of-type(1),
        &:nth-of-type(3) {
          .vs-tabs--btn {
            &::before {
              content: '';
              display: inline-block;
              width: 17px;
              height: 15px;
            }

            &::after {
              content: '';
              display: inline-block;
              position: absolute;
              width: 22px;
              height: 15px;
              left: 1px;
              top: 11px;
            }
          }
        }

        &:nth-of-type(1) {
          .vs-tabs--btn {
            &::after {
              top: 7px;
              height: 21px;
              background: url('./../assets/images/billing/lets-connect-font-icon.svg') center center no-repeat;
              background-size: 15px;
            }
          }

          &.activeChild {
            .vs-tabs--btn {
              &::after {
                top: 7px;
                height: 21px;
                background: url('./../assets/images/billing/lets-connect-font-icon-active.svg') center center no-repeat;
                background-size: 15px;
              }
            }
          }
        }

        &:nth-of-type(3) {
          .vs-tabs--btn {
            &::after {
              background: url('./../assets/images/billing/gpt-connect-font-icon.svg') center center no-repeat;
              background-size: 17px;
            }
          }

          &.activeChild {
            .vs-tabs--btn {
              &::after {
                background: url('./../assets/images/billing/gpt-connect-font-icon-active.svg') center center no-repeat;
                background-size: 17px;
              }
            }
          }
        }
      }
    }
  }

  &__heading {
    text-align: center;
    color: #262629;

    &__title {
      color: #262629;
      font-size: 30px;
      font-weight: 600;
      line-height: 38px;
    }

    &__description {
      margin-top: 15px;
      font-size: 16px;
    }
  }
}
</style>
